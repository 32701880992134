import { FC } from 'react'
import { AppProps } from 'next/app'
import { SWRConfig } from 'swr'
import { AmplitudeClient } from 'amplitude-js'
import { AmplitudeProvider } from 'react-amplitude-hooks'
import { config as faConfig } from '@fortawesome/fontawesome-svg-core'
import { withProfiler } from '@sentry/react'
import '@fortawesome/fontawesome-svg-core/styles.css'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'animate.css'

import pkg from '../package.json'
import * as Sentry from '../utils/sentry'
import fetchJson from '../utils/fetchJson'
import useUser from '../utils/hooks/useUser'
import hotjar from '../vendors/hotjar'

import '../styles/index.css'

faConfig.autoAddCss = false

Sentry.init()

type AppPropsWithErr = AppProps & { err?: Error }

const BrowserApp: FC<AppPropsWithErr> = ({ Component, pageProps, err }) => {
  const { user } = useUser()

  const amplitude = require('amplitude-js') // eslint-disable-line @typescript-eslint/no-var-requires
  const amplitudeInstance = amplitude.getInstance() as AmplitudeClient

  if (amplitudeInstance.isNewSession()) {
    const visitor = new amplitudeInstance.Identify()
    amplitudeInstance.identify(visitor)
  }

  amplitudeInstance.setVersionName(pkg.version)

  if (process.env.NODE_ENV === 'production') {
    hotjar.initialize(process.env.HOTJAR_PROJECT_ID)
  }

  if (user?.isLoggedIn) {
    amplitudeInstance.setUserProperties({
      email: user.email,
      username: [user.firstName, user.lastName].join(' '),
    })

    Sentry.configureScope((scope) => {
      scope.setUser({
        id: user.id,
        email: user.email,
        username: [user.firstName, user.lastName].join(' '),
      })
    })

    // @ts-ignore
    window.hj && window.hj('identify', user.id)
  }

  return (
    <AmplitudeProvider
      amplitudeInstance={amplitudeInstance}
      apiKey={process.env.AMPLITUDE_API_KEY!}
      userId={user?.isLoggedIn ? user.id : undefined}
      config={{ optOut: process.env.NODE_ENV !== 'production' }}
    >
      <Component {...pageProps} err={err} />
    </AmplitudeProvider>
  )
}

const ServerApp: FC<AppPropsWithErr> = ({ Component, pageProps, err }) => {
  return <Component {...pageProps} err={err} />
}

const App: FC<AppProps> = (props) => (
  <SWRConfig value={{ fetcher: fetchJson }}>
    {process.browser ? <BrowserApp {...props} /> : <ServerApp {...props} />}
  </SWRConfig>
)

export default withProfiler(App)
